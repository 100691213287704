'use client';

import { useCallback, useEffect, useState } from 'react';

const convertValueToBool = (value) => {
    if (value === 'true') return true;
    if (value === 'false') return false;
    return value;
};

/**
 * Set a cookie.
 * @param {*} key - cookie name
 * @param {*} value - cookie value
 * @param {*} days - days to expire in. defaults to 14.
 * @param {*} secure - HTTPS access only.
 */
export const setCookie = (key, value, days, secure = true) => {
    if (typeof document === 'undefined') return null;

    const date = new Date();
    const daysToSet = days || 14;
    // Get unix milliseconds at current time plus number of days
    date.setTime(+date + daysToSet * 86400000); // 24 * 60 * 60 * 1000

    window.document.cookie = `${key}=${value}; expires=${date.toGMTString()}; SameSite=strict; path=/; ${secure ? 'Secure;' : ''}`;

    window.dispatchEvent(new Event('cookieChange'));

    return value;
};

/**
 * Deletes a cookie by key
 * @param {*} key - cookie name
 * @param {*} runCookieEvent - run an event to update the watchers
 */
export const deleteCookie = (name, runCookieEvent = true) => {
    document.cookie = `${name}=; Max-Age=-99999999; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;

    if (runCookieEvent) {
        window.dispatchEvent(new Event('cookieChange'));
    }
};

/**
 *
 * @param {*} keyToRetrieve - key of the cookie
 */
export const getCookie = (keyToRetrieve, ensureBool = false) => {
    if (typeof document === 'undefined') return null;
    const cookieString = document.cookie;

    const cookieArr = cookieString.split('; ');
    const cookieObj = {};

    cookieArr.forEach((str) => {
        // str = cookiename=cookievalue
        const [key, val] = str.split('=');

        cookieObj[key] = val;
    });

    const selectedCookie = cookieObj[keyToRetrieve];

    if (selectedCookie === 'null') return null;
    if (selectedCookie === '') return null;

    if (ensureBool) return convertValueToBool(selectedCookie);

    return selectedCookie;
};

/**
 * watches cookie key for any changes.
 * @param {string} key
 * @returns {[any, ()=>void]}
 */
export const useCookieWatcher = (key, ensureBool = false) => {
    const [state, setState] = useState(getCookie(key, ensureBool));

    useEffect(() => {
        const handler = () => {
            const valueFromCookie = getCookie(key, ensureBool);
            if (state === valueFromCookie) return;

            setState(valueFromCookie);
        };

        const visibilityChange = (e) => {
            if (document.visibilityState === 'visible') handler(e);
        };

        window.addEventListener('cookieChange', handler);
        document.addEventListener(
            'visibilitychange',
            visibilityChange
        ); /** For when people load into the tab if they've been working with multiple tabs, resync state. */

        return () => {
            window.removeEventListener('cookieChange', handler);
            document.removeEventListener('visibilitychange', visibilityChange);
        };
    }, []);

    useEffect(() => {
        const newVal = getCookie(key, ensureBool);

        if (newVal) setState(newVal);
    }, [key]);

    const forceCookieRemove = useCallback(() => {
        setState(null);
        deleteCookie(key);
    }, [key]);

    return [state, forceCookieRemove];
};
